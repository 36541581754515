@use "variable" as var;

.modalBody {
  overflow: hidden;
  height: 100%;
  border-radius: 16px;
  background-color: var.$white;
  background-image: url("/images/bg/default/bg_main01.jpg");
  background-size: 100% 100%;
}
.modalInner {
  padding: var.$length16;
}
.modalScrollInner {
  overflow-y: scroll;
  height: 300px;
  padding: var.$length16;
}
.btnModalClose {
  position: absolute;
  top: 0.3%;
  right: 1%;
  z-index: 10;
  width: 36px;
  height: 36px;
}
.btnsWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var.$length16 var.$length16;
  & > * {
    width: calc((100% - 10px) / 2);
    position: relative;
    & + * {
      margin-left: 10px;
    }
    &::before {
      content: "";
      display: block;
      width: 0;
      padding-top: 25%;
    }
    button {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      font-size: 16px;
      font-weight: bold;
      color: var.$white;
      background-size: 100% 100%;
      @include var.textShadow;
    }
  }
}
.btnModalAgree {
  button {
    background-image: url(/images/bg/default/bg_btn_red_l01.png);
  }
}
.btnBack {
  button {
    background-image: url(/images/bg/default/bg_btn_black_l01.png);
  }
}

.infoSpot {
  position: relative;
  height: 100%;
  .img {
    overflow: hidden;
    width: 100%;
    height: 160px;
  }
  .spotName {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    font-weight: bold;
    background-color: var.$colorRgbawhite01;
    text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff,
      -1px -1px 0 #fff;
  }
  .contentArea {
    position: relative;
    height: 200px;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.05);
    .contentInner {
      @include var.scrollbar;
      position: relative;
      height: 100%;
    }
    .spotFeature {
      & > * {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        font-size: 12px;
        & + * {
          margin-top: 0.2em;
        }
        .nm {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.5em;
          background-color: var.$gray04;
        }
        .txt {
          width: calc(100% - 1.5em);
          padding: 0.2em;
          text-align: justify;
        }
      }
    }
    .spotAdd {
      padding: 0.4em;
      margin-top: 1em;
      font-size: 12px;
      background-color: var.$white;
    }
    .spotUrl {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 45px;
        margin: 0.5em auto 0;
        font-weight: bold;
        color: var.$white;
        background-image: url("/images/bg/default/bg_btn_blue_s01.png");
        background-size: 100% 100%;
      }
    }
  }
  .spotAction {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    .btnChallenge {
      width: 180px;
      height: 40px;
      font-weight: bold;
      color: var.$white;
      background-image: url("/images/bg/default/bg_btn_red_l01.png");
      background-size: 100% 100%;
      box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);
    }
  }
}

.precaution {
  overflow-y: scroll;
  height: 300px;
  padding: var.$length16;
  font-size: 12px;
  border: solid 1px var.$gray03;
  & > div {
    &:nth-child(1) {
      margin-bottom: 1em;
    }
    &:nth-child(n + 2) {
      padding: 0 1em;
      text-align: justify;
      text-indent: -1em;
    }
  }
}
.howToPlay {
  overflow-y: scroll;
  height: 240px;
  padding: var.$length16;
  font-size: 14px;
  border: solid 1px var.$gray03;
  text-align: justify;
  & > * {
    position: relative;
    padding: 0 0.5em 0 1em;
    & + * {
      margin-top: 1em;
    }
    // &:last-child {
    //   span {
    //     display: block;
    //   }
    // }
    &::before {
      content: "";
      position: absolute;
      top: 0.2em;
      left: -0.2em;
      display: block;
      width: 12px;
      height: 12px;
      background-color: var.$colorCleared;
    }
  }
}
.gainItem {
  .message {
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
  }
  .itemName {
    position: relative;
    z-index: 10;
    width: calc(280 / 290 * 100%);
    margin: 0 auto;
    &::before {
      content: "";
      display: block;
      width: 0;
      padding-top: calc(40 / 280 * 100%);
    }
    .itemNameInner {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-image: url("/images/frame/default/frame_prize01.png");
      background-size: 100% 100%;
    }
  }
  .imgWrap {
    position: relative;
    z-index: 5;
    margin-top: -9%;
    &::before {
      content: "";
      display: block;
      width: 0;
      // [X] 高さ確認
      padding-top: calc(182 / 290 * 100%);
    }

    .imgInner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("/images/frame/default/frame_prize02.png");
      background-size: 100% 100%;
      .img {
        position: absolute;
        top: 18%;
        left: 10%;
        width: 80%;
        height: 75%;
        span {
          display: block !important;
          max-width: 100%;
          max-height: 100%;
          height: 100% !important;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    &.item {
      &::before {
        padding-top: 100%;
      }
      .imgInner {
        background-image: url("/images/frame/default/frame_prize02_2.png");
      }
    }
  }
  .text {
    padding: 0 1em;
    margin-top: 0.5em;
    font-size: 12px;
    div {
      word-wrap: break-word;
    }
  }
}
.survey {
  .txt {
    font-size: 14px;
  }
  form {
    font-size: 14px;
    button {
      margin-top: 10px;
    }
  }
}

.btnUseWrap {
  .btnUse {
    display: flex !important;
    width: 120px;
    height: 54px;
    margin: 12px auto;
    font-size: 14px !important;
    font-weight: bold !important;
    background-image: url("/images/bg/default/bg_btn_red_s01.png");
    background-size: 100% 100%;
  }
  .popupBox {
    padding: 1em;
    font-size: 12px;
    background-color: var.$white;
    border: solid 2px var.$colorDecide;
    border-radius: 8px;
    .btns {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      & > * {
        width: calc((100% - 12px) / 2);
        height: 30px;
        font-weight: bold;
        background-color: var.$gray04;
        &.btnNext {
          color: var.$white;
          background-color: var.$colorDecide;
          @include var.textShadow;
        }
      }
    }
  }
  .done {
    padding: 1em;
    margin-top: 12px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    background-color: var.$gray04;
  }
}
.btnGoogle,
.btnPass {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 45px;
  margin: 10px auto 0 !important;
  font-size: 12px !important;
  font-weight: bold;
  color: var.$white;
  background-image: url("/images/bg/default/bg_btn_green_l01.png");
  background-size: 100% 100%;
  @include var.textShadow;
}
.btnPass {
  background-image: url("/images/bg/default/bg_btn_green_l02.png");
}

.QrWrap {
  height: 240px;
  background-color: var.$black;
  video {
    width: 100%;
    height: 100%;
  }
}
.acoWrap {
  padding: 8px;
  margin-top: 8px;
  background-color: var.$white;
  border-radius: 8px;

  .acoText {
    font-size: 14px;
    text-align: justify;
  }
  .inputWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    .acoInput {
      appearance: none;
      display: block;
      width: calc(100% - 70px);
      height: 30px;
      padding: 0 0.5em;
      background-color: var.$gray04;
    }
    .btnSend {
      display: block;
      width: 60px;
      height: 30px;
      color: var.$white;
      font-size: 14px;
      font-weight: bold;
      text-shadow: 1px 1px 0 var.$black, -1px -1px 0 var.$black,
        -1px 1px 0 var.$black, 1px -1px 0 var.$black;
      background-image: url("/images/bg/default/bg_btn_an02.png");
      background-size: 100% 100%;
    }
  }
}

.btnHelp {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 45px;
  margin: 10px auto 0 !important;
  font-size: 12px !important;
  font-weight: bold;
  color: var.$white;
  background-image: url("/images/bg/default/bg_btn_green_l01.png");
  background-size: 100% 100%;
  @include var.textShadow;
}

.btnUse {
  position: relative;
  width: 180px;
  height: 44px;
  margin: 10px auto 0;
  button {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 8px 0 12px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    background-image: url("/images/bg/default/bg_btn_yellow_l01.png");
    background-size: 100% 100%;
    .icon {
      width: 20px;
      margin-left: 8px;
    }
    .name {
      width: 5em;
    }
  }
}
